import logo from "./logo.svg";
import React, { useState } from 'react';
import i18n from "i18next";
import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Card,
  TextField,
  Flex
} from "@aws-amplify/ui-react";

import { API } from 'aws-amplify';
import { I18n } from "aws-amplify";
import { useTranslation, initReactI18next } from "react-i18next";
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      es: {
        translation: {
          "We now have Auth!": "Estado de Resultados CRFC",
          "Sign Out": "Cerrar Sesión",
          "From Date": "Desde",
          "To Date": "Hasta",
          "Generate Report": "Generar Reporte",
          "Generating": "Generando",
          "There is an error": "Hay un error"
        }
      }
    },
    lng: "es", // if you're using a language detector, do not define the lng option
    fallbackLng: "es",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const dict = {
  es: {
    'Email': 'Email',
    'Enter your Email': 'Ingrese su correo',
    'Password': 'Contraseña',
    'Enter your Password': 'Ingrese su contraseña',
    'Sign in': 'Iniciar Sesión',
    'Forgot your password?': '¿Olvidó su contraseña?',
    'Reset Password': 'Restablecer contraseña',
    'Enter your email': 'Ingrese su correo',
    'Send code': 'Enviar código',
    'Back to Sign In': 'Volver a Iniciar Sesión',
    'User does not exist.': 'Usuario no existe',
    'Incorrect username or password.': 'Usuario o contraseña incorrecta',
    'Code *': 'Código *',
    'Code': 'Código',
    'New Password': 'Nueva contraseña',
    'Confirm Password': 'Confirmar contraseña',
    'Submit': 'Entregar',
    'Resend Code': 'Reenviar codigo',
    "Signing in": "Iniciando sesión ...",
    "Username/client id combination not found.": "Usuario no encontrado",
    "Sending ...": "Enviando ..."
  }
};

I18n.putVocabularies(dict);
I18n.setLanguage('es');

function App({ signOut }) {
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);


  const generateReport=() => {
    setLoading(true)
    API.post('crfc', '/profit-and-loss-report', {
      body: {
        from: moment(fromDate).utc().add(1, 'days').format('YYYY-MM-DD'),
        to: moment(toDate).utc().format('YYYY-MM-DD')
      },
      headers: {}
    })
    .then(response => {
      console.log(response)
      setLoading(false)
      downloadFile(response.body.url)
    })
    .catch(error => {
      setLoading(false)
      console.log(error)
    })
  }

  const downloadFile = (url) => {
    console.log(url)

    let a = document.createElement('a')
    a.href = url
    a.download = 'report.xlsx'
    a.click()
	}

  const onFromDateChange = (date) => {
    setFromDate(date)
    setToDate(moment(date).endOf('month').toDate())
  }

  return (
    <View className="App">
      <Card style={{maxWidth: '600px', margin: 'auto', paddingTop: '50px', textAlign: 'right'}}>
        <Heading level={1}>{t('We now have Auth!')}</Heading>
        <Button style={{ marginTop: '10px' }} onClick={signOut}>{t('Sign Out')}</Button>
      </Card>

      <Card style={{maxWidth: '600px', margin: 'auto'}}>
        <Flex direction="column">
          <DatePicker
            locale="es"
            selected={fromDate}
            onChange={(date) => onFromDateChange(date)}
            placeholderText={t("From Date")}
            dateFormat={"dd-MM-yyyy"}
            showYearDropdown
            showMonthDropdown
          />
          <DatePicker
            locale="es"
            selected={toDate}
            onChange={(date) => setToDate(date)}
            placeholderText={t("To Date")}
            dateFormat={"dd-MM-yyyy"}
            showYearDropdown
            showMonthDropdown
          />
          {loading && (<p>{t('Generating')} ...</p>)}
          <Button onClick={generateReport} disabled={loading || fromDate == null || toDate == null}>{t("Generate Report")}</Button>
        </Flex>
      </Card>
    </View>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
